import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64a231ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { ref: "element" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseImage = _resolveComponent("BaseImage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: _normalizeClass(["scroll-up", { 'show-scroll-up': _ctx.state.showButton }]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.scrollUp && _ctx.scrollUp(...args)))
    }, [
      _createVNode(_component_BaseImage, {
        class: "w-[6.4rem] lg:w-[8.5rem]",
        icon: "scroll_up"
      })
    ], 2),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 512))
}