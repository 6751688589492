
import { defineComponent, PropType } from 'vue';
import { routes } from '@/shared/constants/routes';
import { Product } from '@/shared/interfaces/product';
import ProductCard from '@/shared/components/ProductCard/ProductCard.vue';
import InfiniteScroll from '@/shared/components/InfiniteScroll/InfiniteScroll.vue';

export default defineComponent({
  name: 'ProductsInfiniteScroll',
  components: {
    ProductCard,
    InfiniteScroll,
  },
  props: {
    products: { type: Array as PropType<Product[]>, required: true },
    isLoadable: { type: Boolean, required: true },
    page: { type: Number, default: 1 },
  },
  emits: ['loadMore'],
  setup() {
    return { routes };
  },
});
