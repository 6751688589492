import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProductCard = _resolveComponent("ProductCard")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_BaseContainer = _resolveComponent("BaseContainer")!
  const _component_InfiniteScroll = _resolveComponent("InfiniteScroll")!

  return (_openBlock(), _createBlock(_component_InfiniteScroll, {
    class: "bg-white",
    isLoadable: _ctx.isLoadable,
    page: _ctx.page,
    onLoadMore: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('loadMore'))),
    offset: 500
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BaseContainer, { class: "cards-container" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product, index) => {
            return (_openBlock(), _createBlock(_component_router_link, {
              key: product.id,
              to: _ctx.routes.productDetails.path(product.id),
              class: "card"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ProductCard, {
                  product: product,
                  isSmall: !!(index % 5)
                }, null, 8, ["product", "isSmall"])
              ]),
              _: 2
            }, 1032, ["to"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["isLoadable", "page"]))
}