
import { defineComponent, ref, onMounted, onUnmounted, reactive } from 'vue';
import throttle from 'lodash/throttle';

export default defineComponent({
  name: 'InfiniteScroll',
  props: {
    isLoadable: { type: Boolean, required: true },
    page: { type: Number, default: 1 },
    offset: { type: Number, default: 200 },
  },
  emits: ['loadMore'],
  setup(props, { emit }) {
    const element = ref<HTMLDivElement>();
    const SCROLL_THROTTLE = 200;
    const state = reactive({
      showButton: false,
    });

    const checkAvailableSpace = throttle(() => {
      if (
        !props.isLoadable &&
        (element.value?.getBoundingClientRect().bottom ?? 0) < window.innerHeight + props.offset
      ) {
        emit('loadMore');
      }

      state.showButton = (element.value?.getBoundingClientRect().top ?? 0) < 0;
    }, SCROLL_THROTTLE);

    const scrollUp = () => {
      element.value?.scrollIntoView({ behavior: 'smooth' });
    };

    onMounted(() => {
      window.addEventListener('scroll', checkAvailableSpace);
    });
    onUnmounted(() => {
      window.removeEventListener('scroll', checkAvailableSpace);
    });

    return { element, scrollUp, state };
  },
});
