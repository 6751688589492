import { ProductDetails } from '@/shared/interfaces/productDetails';

interface ICategoriesIcons {
  [key: number]: string;
}

interface UseFallbackImage {
  getFallbackImage: (product: ProductDetails) => string;
  categoriesIcons: ICategoriesIcons;
  defaultCategoryIcon: string;
}

const categoriesIcons: ICategoriesIcons = {
  4782: 'mini_excavator',
  4797: 'excavator',
  4798: 'loader',
  4799: 'caterpillars',
  4800: 'dumper',
  4801: 'compression',
  4802: 'transfer',
  4803: 'equipment',
  4804: 'site_equipment',
  4805: 'cleaning',
  6413: 'attachments',
  9069: 'mini_excavator',
  9070: 'excavator',
  9071: 'loader',
  9072: 'caterpillars',
  9073: 'dumper',
  9074: 'attachments',
  11578: 'compaction',
  11585: 'trailer',
  11994: 'screening',
  11509: 'garden',
};

const defaultCategoryIcon = 'equipment';
const attachmentId = 6413;

export const useFallbackImage = (): UseFallbackImage => {
  const getFallbackImage = (product: ProductDetails): string =>
    product.is_attachment
      ? categoriesIcons[attachmentId]
      : categoriesIcons[product.mainCategoryId] || defaultCategoryIcon;
  return { getFallbackImage, categoriesIcons, defaultCategoryIcon };
};
